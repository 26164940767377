import React from 'react'
// import { Link } from "gatsby"
import {
  FaRegNewspaper,
  FaUser,
  FaSun,
  FaLandmark,
  FaMoneyCheckAlt,
  FaBook,
  FaSearch,
  FaUsers,
  FaFileAlt,
  FaClock,
  FaTv,
  FaMoneyBillWave,
  FaGavel,
} from 'react-icons/fa'
import Layout from '../components/layout'
import SEO from '../components/seo'

const ResourcesPage = () => (
  <Layout>
    <SEO title="Resources" />
    <div className="subheader-img-resources" />
    <div className="resources-page-wrapper">
      <h2>Resources</h2>
      <div className="resources-list-wrapper-main">
        <div className="resources-section">
          <h3>Legislative Resources</h3>
          <div className="resources-legislative-main">
            <div className="resources-legislative-left">
              <ul>
                <li>
                  <a
                    href="https://capitol.texas.gov/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FaLandmark className="resource-icon" />
                    Texas Legislature Online
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.lbb.state.tx.us/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FaMoneyCheckAlt className="resource-icon" />
                    Legislative Budget Board
                  </a>
                </li>
                <li>
                  <a
                    href="https://hro.house.texas.gov/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FaSearch className="resource-icon" />
                    House Research Organization
                  </a>
                </li>
                <li>
                  <a
                    href="https://lrl.texas.gov/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FaBook className="resource-icon" />
                    Legislative Reference Library
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.sunset.texas.gov/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FaSun className="resource-icon" />
                    Texas Sunset Commission
                  </a>
                </li>
                <li>
                  <a
                    href="https://tlc.texas.gov/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FaLandmark className="resource-icon" />
                    Legislative Council
                  </a>
                </li>
                <li className="resources-small-link">
                  <a
                    href="https://www.senate.texas.gov/av-live.php"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FaTv className="resource-icon" />
                    Senate Live Broadcasts
                  </a>
                </li>
                <li className="resources-small-link">
                  <a
                    href="https://house.texas.gov/video-audio/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FaTv className="resource-icon" />
                    House Live Broadcasts
                  </a>
                </li>
                <li>
                  <a
                    href="https://gov.texas.gov/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FaLandmark className="resource-icon" />
                    Office of the Governor
                  </a>
                </li>
                <li>
                  <a
                    href="https://comptroller.texas.gov/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FaMoneyBillWave className="resource-icon" />
                    Comptroller
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.texasattorneygeneral.gov/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FaGavel className="resource-icon" />
                    Attorney General
                  </a>
                </li>

                <li>
                  <a
                    href="https://www.sos.state.tx.us/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FaLandmark className="resource-icon" />
                    Secretary of State
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.ethics.state.tx.us/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FaGavel className="resource-icon" />
                    Ethics Commission
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.texas.gov/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FaLandmark className="resource-icon" />
                    Texas.gov
                  </a>
                </li>
                <li className="resources-small-link">
                  <a
                    href="https://txlege-countdown.netlify.app/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FaClock className="resource-icon" />
                    Legislative Countdown Clock
                  </a>
                </li>
              </ul>
            </div>
            <div className="resources-legislative-right">
              <ul>
                <li>
                  <a
                    href="https://wrm.capitol.texas.gov/home"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FaUser className="resource-icon" />
                    Who Represents Me?
                  </a>
                </li>
                <li>
                  <a
                    href="https://txlege-countdown.netlify.app/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FaClock className="resource-icon" />
                    Legislative Countdown Clock
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.senate.texas.gov/av-live.php"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FaTv className="resource-icon" />
                    Senate Live Broadcasts
                  </a>
                </li>
                <li>
                  <a
                    href="https://house.texas.gov/video-audio/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FaTv className="resource-icon" />
                    House Live Broadcasts
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="resources-section">
          <h3>News</h3>
          <ul>
            <li>
              <a
                href="https://www.texastribune.org/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FaRegNewspaper className="resource-icon" />
                Texas Tribune
              </a>
            </li>
          </ul>
        </div>
        <div className="resources-section">
          <h3>Policy Organizations</h3>
          <ul>
            <li>
              <a
                href="https://everytexan.org/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FaUsers className="resource-icon" />
                Every Texan
              </a>
            </li>
            <li>
              <a
                href="https://www.texaspolicy.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FaFileAlt className="resource-icon" />
                Texas Public Policy Foundation
              </a>
            </li>
          </ul>
        </div>
        <div className="resources-section resources-small-link">
          <h3>Constituent Resources</h3>
          <ul>
            <li>
              <a
                href="https://wrm.capitol.texas.gov/home"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FaUser className="resource-icon" />
                Who Represents Me?
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </Layout>
)

export default ResourcesPage
